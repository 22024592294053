.container {
  flex-direction: column;
  gap: 28px;
}

.sectionTitle {
  text-transform: uppercase;
  color: #525c60;
  font-size: 20px;
  font-weight: 600;
}

.section {
  width: 100%;
  margin-bottom: 15px;
}

.inputContainer {
  background-color: #fff;
  height: 46px;
  border-radius: 5px;
}

.headerInfo p {
  color: #525c60;
  font-size: 0.9rem;
  max-width: 60vw;
}
