.container {
  flex-direction: column;
  gap: 15px;
}

.container h2 {
  color: #525c60;
  font-weight: 600;
  font-size: 1.7rem;
}

.text {
  justify-content: space-between;
}

.text p {
  font-size: 0.9rem;
  max-width: 60vw;
}
