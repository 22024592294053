.title {
  color: #525c60;
  font-weight: 600;
  font-size: 1.7rem;
}

.section {
  margin-bottom: 10px;
}

.section h4 {
  text-transform: uppercase;
  color: #ababab;
  font-size: 16px;
  font-weight: 500;
}

.section p {
  font-size: 15px;
  color: #525c60;
}

.contentContainer {
  position: relative;
}

.editComponent {
  position: absolute;
  right: 15px;
}
