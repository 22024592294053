.container {
  width: 100%;
  height: 70vh;
  background-color: #fff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  flex-direction: column;
}

.scroll {
  min-height: calc(100% - 220px);
  max-height: calc(100% - 220px);
  overflow-y: auto;
  width: 100%;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.messagesContainer {
  width: 100%;
  padding: 42px;
  width: 100%;
  gap: 20px;
}

.adminMessage {
  width: 100%;
  justify-content: flex-start;
}

.adminMessage .message {
  padding: 20px;
  background-color: #f0f2f5;
  width: 70%;
}

.clientMessage {
  width: 100%;
  justify-content: flex-end;
}

.clientMessage .message {
  padding: 20px;
  background-color: #2bbc9d;
  color: #fff;
  width: 70%;
}

.sendBox {
  height: 220px;
  width: 100%;
  padding: 42px;
  position: relative;
}

.line {
  position: absolute;
  top: 0;
  left: 42px;
  border: 0px;
  height: 1px;
  width: calc(100% - 84px);
  background-color: #e2e8f0;
}
