.title {
  flex-direction: column;
}

.title h2 {
  color: #525c60;
  font-weight: 600;
  font-size: 1.7rem;
}

.title p {
  font-size: 0.9rem;
  max-width: 60vw;
}

/* contenedor de las dos secciones: progress y form*/
.modal {
  height: 100%;
  width: 95%;
  justify-content: space-between;
}