.formSection {
  background-color: #f7f7f7;
  width: 65%;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  max-height: 100%;
  overflow-y: auto;
}

.formContainer {
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.form {
  width: 85%;
  height: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonsContainer {
  justify-content: space-between;
  align-items: center;
}

.buttonsContainer h6 {
  color: #ABABAB;
  font-size: 0.9rem;
  cursor: pointer;
}