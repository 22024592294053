@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.formCategoryTitle {
  color: #0aaa9d;
  font-weight: 600;
  letter-spacing: 0.4px;
  font-size: 18px;
  margin-bottom: 25px;
}
