.container {
  height: 72px;
  width: 100%;
  border-bottom: 2px solid #ababab;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #525c60;
  font-weight: 500;
}
