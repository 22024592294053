.container {
  position: relative;
  background-image: url("https://res.cloudinary.com/dsxfufacm/image/upload/v1692723758/Taxon/backgroundImage_fscyhs.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

.subContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
