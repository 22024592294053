.check {
  cursor: pointer;
  filter: grayscale(100%);
  opacity: 30%;
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.check:hover {
  filter: none;
  opacity: 100%;
}
