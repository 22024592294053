.container {
    flex-direction: column;
    gap: 28px;
}
.componentContainer {
    flex-direction: column;
    gap: 15px;
}

.componentContainer h2{
    color: #525C60;
    font-weight: 600;
    font-size: 1.7rem;
}

.componentText {
    justify-content: space-between;

}

.componentText p {
    /*color: #525C60;*/
    font-size: 0.9rem;
    max-width: 60vw;
}