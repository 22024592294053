.container {
  height: 0px;
  padding-left: 15px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link {
  width: 35px;
  font-size: 14px;
  font-weight: 500;
}
