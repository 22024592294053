/* contenedor de la seccion progress */
.progressSection {
  background-color: #f0faf9;
  width: 35%;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
}

.description h2 {
  color: #000100;
  font-size: 1.3rem;
  font-weight: 700;
}

.description p {
  font-size: 0.9rem;
}

.text {
  flex-direction: column;
  gap: 5px;
}

.progressBar {
  flex-direction: column;
  gap: 5px;
  width: 80%;
}

.item {
  display: flex;
  align-items: flex-end;
  gap: 14px;
}

.itemBulletContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.line {
  height: 54px;
  width: 0px;
}

.itemBullet {
  width: 33px;
  height: 33px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.itemBullet p {
  color: #999f9d;
  font-weight: 500;
  font-size: 1rem;
}

.itemName p {
  font-weight: 500;
  margin-bottom: 4.5px;
  font-size: 0.9rem;
}

.active .itemBullet {
  background-color: #0aaa9d;
  transition: background-color 1s ease;
}
.active .itemBullet p {
  color: #fff;
}
.active .itemName p {
  color: #000100;
}
.activeLine {
  border: 1.5px solid #0aaa9d;
  transition: border 0.5s ease;
}

.inactive .itemBullet {
  background-color: #dce6e5;
  transition: background-color 1s ease;
}
.inactive .itemName p {
  color: #999f9d;
}
.inactiveLine {
  border: 1.5px solid #dce6e5;
  transition: border 0.5s ease;
}
