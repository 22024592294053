.title {
  color: #525c60;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.sectionContainer {
  width: 100%;
}

.itemName {
  color: #525c60;
  font-weight: 500;
  font-size: 14px;
}

.servicePrice {
  height: 47px;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.serviceCurrency {
  background-color: #f7f7f7;
  height: 47px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #f7f7f7;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
}