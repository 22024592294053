.sectionName {
  color: #525c60;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.itemContainer {
  margin-bottom: 20px;
  justify-content: space-between;
}

.itemName {
  width: 310px;
}

.itemPrice {
  height: 47px;
  background-color: #f7f7f7;
  border-radius: 5px;
}

.itemCurrency {
  background-color: #f7f7f7;
  height: 47px;
  width: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #f7f7f7;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
}

@media screen and (max-width: 967px) {
  .itemContainer {
    flex-direction: column;
  }
  .itemPrice {
    width: 238px;
    margin-top: 10px;
  }
}
