.container {
  width: 30%;
  border-right: 2px solid #ababab;
  height: 80vh;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.scroll {
  min-height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.avatar {
  background-color: #b9c0c9;
  border-radius: 50px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.avatarLetter {
  text-transform: uppercase;
}

.conversation {
  height: 72px;
  width: 100%;
  border-bottom: 2px solid #ababab;
  padding-left: 27px;

  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;

  color: #525c60;
  font-weight: 500;
  background-color: #fff;
}

.conversation:hover {
  background-color: #f0faf9;
  cursor: pointer;
}

.newMessage {
  background-color: #f0faf9;
}

.newMessageIcon {
  position: absolute;
  right: 27px;
}
