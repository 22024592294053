.contCheckbox {
  /*width: 205px;*/
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
}

.contCheckbox label {
  position: relative;
  padding-left: 20px; /* Espacio para el radio personalizado */
}

.contCheckbox input {
  position: absolute;
  opacity: 0; /* Invisibiliza el radio pero permite su funcionalidad */
  cursor: pointer;
  z-index: 10;
  width: 26px;
  height: 26px;
}

.contCheckbox label::before {
  content: "";
  position: absolute;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.contCheckbox input:checked + label::before {
  border: 0px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  content: url(../../../../assets//img/icons/check.png);
}

.text {
  margin-left: 5px;
  text-align: left;
  color: #525c60;
  font-weight: 500;
  font-size: 14px;
}
